import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql, StaticQuery } from "gatsby"
import { BLOCKS, INLINES } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

const contactQuery = graphql`
  query getContactQuery {
    allContentfulAsset {
      edges {
        node {
          title
          file {
            url
          }
        }
      }
    }
    allContentfulContact {
      nodes {
        text {
          json
        }
      }
    }
  }
`

const options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <span>
        {children}
        <br />
      </span>
    ),
    [INLINES.ASSET_HYPERLINK]: node => {
      return (
        <a
          href={node.data.target.fields.file["en-US"].url}
          style={{ textDecoration: "none" }}
        >
          {node.content[0].value}
        </a>
      )
    },
  },
}

class ContactPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <StaticQuery
        query={contactQuery}
        render={data => {
          const image = data.allContentfulAsset.edges.filter(edge => {
            return edge.node.title === "Björn, orange"
          })[0].node

          const contact = data.allContentfulContact.nodes[0].text.json

          return (
            <Layout>
              <SEO
                title="Kontakt"
                keywords={[
                  `Björn Wessman`,
                  `Kontakt`,
                  `Telefon`,
                  `Mail`,
                  `Agent`,
                ]}
              />
              <div className={"row"}>
                <div className={"seven columns"}>
                  {documentToReactComponents(contact, options)}
                </div>
                <div className={"five columns"}>
                  <img
                    style={{ width: "100%" }}
                    src={image.file.url}
                    alt={image.title}
                  />
                </div>
              </div>
            </Layout>
          )
        }}
      />
    )
  }
}

export default ContactPage
